<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=9 />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">申请注册认证<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="form" :label-position="labelPosition" :rules="rules" label-width="100px"
                    class="elform" ref="elForm">
                    <el-form-item class="formL" label="注册号" prop="registerno">
                      <el-input v-model="form.registerno"></el-input>
                    </el-form-item>
                    <el-form-item class="formL" label="执业机构" prop="employer">
                      <el-select v-model="form.employer" filterable remote reserve-keyword placeholder="请输入关键词"
                        :remote-method="ongetcompanyquerylist" :loading="loading" @change="onemployerchange">
                        <el-option v-for="item in employerlist" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item class="formL " label="有效期至" prop="expirydate">
                      <el-date-picker type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"
                        v-model="form.expirydate" style="width: 164px;margin-right:10px">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="注册证书" prop="CertRegistImg">
                      <el-upload class="" :headers="myHeaders" list-type="picture-card" :on-remove="handleRemovereg"
                        :action="uploadUrl" name="file" accept="image/png,image/gif,image/jpg,image/jpeg"
                        :file-list="fileListReg" :on-success="busSuccessreg" :on-error="busError"
                        :on-change="busChangereg" :show-file-list="false">
                        <img v-if="form.CertRegistImgUrl!=''" style="width:100%;height:100%;"
                          :src="form.CertRegistImgUrl" class="avatar">
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import { getcompanyquerylist } from '@/api/company';
import { getCookieByName } from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  professioncertregauth,
  getprofessioncertinfo,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      active: 1,
      labelPosition: 'right',
      fileListReg: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      isauthresult: 1,
      form: {
        registerno: '',
        employer: '',
        expirydate: '',
        CertRegistImg: '',
        CertRegistImgUrl: '',
        EmployerCompanyId: '',
        Id:'',
      },
      rules: {
        registerno: [{ required: true, message: '请填写注册号' }],
        employer: [{ required: true, message: '请填写执业机构' }],
        expirydate: [{ required: true, message: '请填写有效期至' }],
        CertRegistImg: [{ required: true, message: '请上传注册证书' }],
      },
      employerlist: [],
      loading: false,
    };
  },
  methods: {
    ongetprofessioncertinfo(){
      getprofessioncertinfo({id:this.form.id}).then(res=>{
        if (res.data.code == 1) {
          this.form.registerno = res.data.result.registerNO
          this.form.employer = res.data.result.employer
          this.form.EmployerCompanyId = res.data.result.employerCompanyId;
          this.form.expirydate = res.data.result.expiryDate
          this.form.CertRegistImg = res.data.result.certRegistImg
          this.form.CertRegistImgUrl = res.data.result.certRegistImgOUrl
        }
      })
    },
    onemployerchange(item) {
      const self = this
      console.log(item)
      this.form.EmployerCompanyId = item;
      this.employerlist.forEach((res,i) => {
        if (res.id == item) {
          self.form.employer = res.name;
        }
      });
    },
    ongetcompanyquerylist(query) {
      if (query !== '') {
        this.loading = true;
        let params = { nameKeywords: query };
        getcompanyquerylist(params).then((res) => {
          if (res.data.code == 1) {
            this.loading = false;
            this.employerlist = res.data.result;
            this.form.employer = query;
          }
        });
      }
    },
    onsubmitForm(formName) {
      console.log(this.form.employer)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {
            RegisterNO:this.form.registerno,
            Employer:this.form.employer,
            EmployerCompanyId:this.form.EmployerCompanyId,
            ExpiryDate:this.form.expirydate,
            CertRegistImg:this.form.CertRegistImg,
            Id:this.form.id,
          }
          console.log(param)
          professioncertregauth(param).then((res) => {
            if (res.data.code === 1) {
                this.$message({
                  message: '恭喜你，提交成功',
                  type: 'success',
                });
                setTimeout(function () {
                  window.history.go(-1);
                }, 300);
            } else {
              this.$message.error(res.data.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    handleRemovereg(file, fileList) {
      console.log(file, fileList);
    },
    busSuccessreg(response, file, fileList) {
      var that = this;
      this.fileListReg = fileList;
      if (response.code == 1) {
        this.form.CertRegistImg = response.result.fileToken;
        this.form.CertRegistImgUrl = response.result.url + '/raw';
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChangereg(file, fileList) {
      if (fileList.length > 0) {
        this.fileListReg = [fileList[fileList.length - 1]];
      }
    },
  },
  created() {
  },
  mounted() {
    screenLeft;
    if(this.$route.query.id){
      this.form.id = this.$route.query.id
      this.ongetprofessioncertinfo()
    }
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
  border: 1px solid #b3d8ff;
}
.accountset >>> form.elform {
  width: 540px;
}
/**公共css结束**/
.accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../assets/img/account-ico.png');
}

.accountset .stepone .el-row {
  width: 90%;
  margin: 0 auto;
}
.accountset .color-box {
  position: relative;
  border-radius: 4px;
  padding: 20px;
  margin: 5px 0;
  height: 114px;
  box-sizing: border-box;
  color: #fff;
  font-size: 14px;
  background: #7cbcff;
  box-shadow: 0 3px 8px 0 rgb(47 24 24 / 10%);
  transition: all 0.2s linear;
  cursor: pointer;
}
.accountset .color-box:hover {
  transform: translate3d(0, -2px, 0);
}
.accountset .bg-color-sub {
  width: 100%;
  height: 40px;
  left: 0;
  bottom: 0;
  position: absolute;
}
.accountset .stepline {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.accountset .color-box .icon {
  font-size: 26px;
  text-align: center;
}
.accountset .bg-success-sub-item {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: #ecf5ff;
  color: #5f5f5f;
  font-size: 16px;
  text-align: center;
  line-height: 35px;
}
.accountset .el-col {
  /* padding-left: 6px; */
  padding-right: 6px;
}
.accountset .info-cnt >>> .el-form-item__content button.btnsave {
  color: #409eff;
  background: #ecf5ff;
  border: 1px solid #b3d8ff;
  background-image: none;
}
</style>
